@media screen and (min-width:700px) {
    .main {
        margin-top: 5em;
        margin-left: 1em;
        margin-right: 1em;
        margin-bottom: 1em;
    }
    /* GRID TYPE 1 */
    .grid-container-type-1 .grid-item-1,
    .grid-container-type-1 .grid-item-2,
    .grid-container-type-1 .grid-item-3,
    .grid-container-type-1 .grid-item-4 {
        max-width: 50em;
        margin-left: auto;
        margin-right: auto;
    }
    @supports (display: grid) {
        /* WHITEBOARD GREENCARD */
        .whiteboard,
        .green-card {
            margin: 0;
        }
        /* GRID TYPE 1 */
        .grid-container-type-1 {
          max-width: 72em;

          display: grid;
          grid-template-columns: 5fr 4fr;
          grid-auto-rows: auto;
          grid-column-gap: 1.25em;
          grid-row-gap: 1.25em;
          grid-auto-flow: dense;
        }
        .grid-container-type-1 .grid-item-1,
        .grid-container-type-1 .grid-item-2,
        .grid-container-type-1 .grid-item-3,
        .grid-container-type-1 .grid-item-4 {
          max-width: initial;
          margin-left: initial;
          margin-right: initial;
        }
        .grid-container-type-1 .grid-item-1,
        .grid-container-type-1 .grid-item-2,
        .grid-container-type-1 .grid-item-3 {
          grid-column-start: 1;
          grid-column-end: 2;
        }
        .grid-container-type-1 .grid-item-4 {
          grid-column-start: 2;
          grid-column-end: -1;
          grid-row: 1 / span 3;
        }
    }
}